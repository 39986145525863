import { SeeqNames } from '@/main/app.constants.seeqnames';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { generateRequestId, getOriginLabel, getOriginUrl } from '@/utilities/http.utilities';
import { Agent, agents, getDataLabAiAgents, GroupedHistoryEntryType, HistoryEntryType } from './aiAssistant.types';
import { isDataLabAiAssistant } from '@/main/routing.utilities';
import { aiAssistantEnabled, genAIAgents, genAIEnabled } from '@/services/systemConfiguration.utilities';
import { isSystemTest } from '@/core/utilities';
import { isMobileDevice } from '@/utilities/utilities';
import { APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import { isToday, isWithinInterval, isYesterday, subDays } from 'date-fns';

function headers() {
  return {
    [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
    [SeeqNames.API.Headers.RequestOrigin]: 'Analysis',
    [SeeqNames.API.Headers.RequestOriginURL]: getOriginUrl(),
    [SeeqNames.API.Headers.RequestOriginLabel]: getOriginLabel(),
    [SeeqNames.API.Headers.RequestId]: generateRequestId(),
  };
}

/**
 * Gets the Chats for the current user.
 *
 * @returns  A chronologically ordered dictionary of chat conversations
 */
export async function getChats(): Promise<Record<string, HistoryEntryType>> {
  const response = await fetch('/genai/chats', { method: 'GET', headers: headers() });
  return await response.json();
}

export async function deleteChat(chatId: string): Promise<void> {
  await fetch(`/genai/chats?chat_id=${chatId}`, { method: 'DELETE', headers: headers() });
}

export function getFriendlyMarkup(id: string) {
  const element = document.getElementById(id);
  if (!element) return '';

  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  if (!selection) return '';

  selection.removeAllRanges();
  selection.addRange(range);
  const text = selection.toString();
  selection.removeAllRanges();
  return text;
}

export function copyToClipboard(id: string, onCopyFinish = () => {}, { clearSelection } = { clearSelection: true }) {
  const element = document.getElementById(id);
  if (!element) return;

  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  if (!selection) return;

  selection.removeAllRanges();
  selection.addRange(range);
  const success = document.execCommand('copy');

  if (clearSelection) selection.removeAllRanges();
  if (success) onCopyFinish();
}

export function getAgents(): Agent[] {
  const availableAgents = isDataLabAiAssistant() ? getDataLabAiAgents() : genAIAgents()?.toLowerCase();
  return agents.filter((agent) => availableAgents?.includes(agent.key));
}

export function getDefaultDisplayed(): boolean {
  return !isSystemTest() && !isMobileDevice() && aiAssistantEnabled() && genAIEnabled();
}

export const updateChat = async (chatId: string, { favorite, name }: { favorite?: boolean; name?: string }) => {
  return await fetch(`/genai/chats/${chatId}`, {
    method: 'PUT',
    headers: { ...headers(), 'content-type': APPSERVER_API_CONTENT_TYPE },
    body: JSON.stringify({
      favorite: favorite === undefined ? null : favorite,
      name: name === undefined ? null : name,
    }),
  });
};

export const groupChats = (chats: Record<string, HistoryEntryType>): GroupedHistoryEntryType => {
  const today = new Date();
  const groupedChats: GroupedHistoryEntryType = {
    favorites: [],
    today: [],
    yesterday: [],
    last_week: [],
    last_month: [],
    older: [],
  };

  const determineCategory = (
    updatedAt: string | undefined | null,
    favorite: boolean,
  ): keyof GroupedHistoryEntryType => {
    if (favorite) return 'favorites';
    if (!updatedAt) return 'older';

    const updatedAtDate = new Date(updatedAt);
    if (isNaN(updatedAtDate.getTime())) return 'older';

    if (isToday(updatedAtDate)) return 'today';
    if (isYesterday(updatedAtDate)) return 'yesterday';
    if (isWithinInterval(updatedAtDate, { start: subDays(today, 7), end: today })) return 'last_week';
    if (isWithinInterval(updatedAtDate, { start: subDays(today, 30), end: today })) return 'last_month';

    return 'older';
  };

  Object.entries(chats).forEach(([chatId, chatData]) => {
    const category = determineCategory(chatData.updatedAt, chatData.favorite);

    groupedChats[category].push({
      ...chatData,
      displayText: chatData.name || chatData.messages[0]?.dialog?.substring(0, 100) || 'No message available',
      chatId,
    });
  });

  return groupedChats;
};
